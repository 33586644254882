.backgroundVideo {
    width: 300px;
    height: 300px;
    border: 1px solid red;
    background: url(https:\/\/youtu.be\/9MRX86vrYSc);
}

.typeWriterAnimationTextOne {
    color: #fff;
    /* font-family: monospace; */
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    animation: 
      typing 7s infinite;

}
.typeWriterAnimationTextTwo {
    color: #fff;
    /* font-family: monospace; */
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      /* typing 3.5s steps(30, end), */
      typing 9s  infinite;

      /* blink-caret .5s step-end infinite; */
}
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }


  
  
  /* The typewriter cursor effect */
  /* @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
  } */
  .homeCertificateParent{
    animation: scale-in-center .8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  }
.homeCertificate {
    animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.homeSection {
    width: 100vw;
    height: 80vh;
    background-image:
    linear-gradient(90deg,rgba(0,0,0,.5) 10%,rgba(0,0,0,.2)),url(https://www.cio.com/wp-content/uploads/2021/12/data_center_corridor_server_room_with_abstract_overlay_of_networked_connections_by_sdecoret_gettyimages-1197646065_2400x1600-100857776-orig-1.jpg?quality=50&strip=all);

    /* linear-gradient(90deg,rgba(0,0,0,.5) 10%,rgba(0,0,0,.2)),url(https://cdn.ttgtmedia.com/rms/onlineimages/storage_g1197646065.jpg); */

    /* linear-gradient(90deg,rgba(0,0,0,.5) 10%,rgba(0,0,0,.2)),url(https://t4.ftcdn.net/jpg/05/28/90/43/360_F_528904357_wRur2TnRmUKXgRnbrSF8CYewb6aNgZ9S.jpg); */
    /* background-image: linear-gradient(310deg, #FFFFFF 0%, #000000 100%), url(https://t4.ftcdn.net/jpg/05/28/90/43/360_F_528904357_wRur2TnRmUKXgRnbrSF8CYewb6aNgZ9S.jpg); */
    /* background-image: url(https://t4.ftcdn.net/jpg/05/28/90/43/360_F_528904357_wRur2TnRmUKXgRnbrSF8CYewb6aNgZ9S.jpg); */
    /* background-image: url(https://thumbs.gfycat.com/AccurateLimpingAchillestang-max-1mb.gif); */
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.homeSectionTextWrapper {
    animation:slide-right 1s cubic-bezier(.25,.46,.45,.94) both;
    background: linear-gradient(90deg, #fff, 
    #FF5722, #fff);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 3s linear infinite;
    /* animation-delay: 2s; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255,.5);

}
@keyframes animate {
    0% {
      background-position: -500%;
    }
    100% {
      background-position: 500%;
    }
  }

.homeSectionSecondPart {
    /* height: 200px; */
    background: #ECECEC;
    animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}


.homeSectionSecondPartChild {
    max-width: 185px;
    padding: 2rem;
    position: relative;
    margin-top: -50px;
}



#homeSectionIcons {
    font-size: 2rem;
    margin-top: .9rem;

    color: white;
}
.homeSectionIconParent {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
        background: #FF5722;
        width: 60px;
        height: 60px;
        border-radius: 5px;
}
.homePageThirdDivSecondChild {
    animation: slide-in-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.homePageThirdDivFirstP::before {
 content: "";
 width: 50px;
 height:5px;
 background-color: #FF5722;
 position: absolute;
 left: 0;
 top: 50%;
 transform: translateY(-50%);
}
.homePageFifthDiv {
    width: 100vw;
    height: 60vh;
    /* background:  */
    /* linear-gradient(90deg,rgba(0,0,0,.5) 10%,rgba(0,0,0,.2)),url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/map-Q676QDA.jpg); */

    /* background-image: url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/map-Q676QDA.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: 72% auto; */
}
.firstBgFixedDiv {
    background: 
    linear-gradient(90deg,rgba(0,0,0,.8) 10%,rgba(0,0,0,.5)),url(https://customersfirstacademy.com/wp-content/uploads/2021/04/How-to-keeping-customers-happy.jpg);
    /* background-image: url(https://www.qualityformationsblog.co.uk/wp-content/uploads/2020/04/QCF_Meeting-1.jpg); */
    background-attachment: fixed;
    width: 100vw;
    height: 400px;
    position:relative
}
.backgroundFixedDiv {
    background: 
    linear-gradient(90deg,rgba(0,0,0,.8) 10%,rgba(0,0,0,.5)),url(https://www.qualityformationsblog.co.uk/wp-content/uploads/2020/04/QCF_Meeting-1.jpg);
    /* background-image: url(https://www.qualityformationsblog.co.uk/wp-content/uploads/2020/04/QCF_Meeting-1.jpg); */
    background-attachment: fixed;
    width: 100vw;
    height: 400px;
    position:relative

}
/* .backgroundFixedDivUpperLayer {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */
.firstBgFixedDivChild {
    color: white;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* .homePageDivSecondCild {
    position: absolute;
    bottom: -150px;
    left: 50%;
    transform: translateX(-50%);
} */

.hoverChageColor:hover {
    background: #FF5722;
    color: white;
    scale: 1.05;
    transition: .8s
}
.homeSecionSecondPartChildHover:hover {
    background: #ECECEC !important;
    color: #FF5722 !important;
    transition: .5s;
}
.hoverChageColor:hover>i {
    color: white !important
}


@keyframes slide-right{0%{transform:translateX(-500px)}100%{transform:translateX(0px)}}
@keyframes flicker-in-1{0%{opacity:0}10%{opacity:0}10.1%{opacity:1}10.2%{opacity:0}20%{opacity:0}20.1%{opacity:1}20.6%{opacity:0}30%{opacity:0}30.1%{opacity:1}30.5%{opacity:1}30.6%{opacity:0}45%{opacity:0}45.1%{opacity:1}50%{opacity:1}55%{opacity:1}55.1%{opacity:0}57%{opacity:0}57.1%{opacity:1}60%{opacity:1}60.1%{opacity:0}65%{opacity:0}65.1%{opacity:1}75%{opacity:1}75.1%{opacity:0}77%{opacity:0}77.1%{opacity:1}85%{opacity:1}85.1%{opacity:0}86%{opacity:0}86.1%{opacity:1}100%{opacity:1}}
@keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  .CheersTo {
    margin-top: 10rem;
  }

  @media  screen and (min-width:480px)  {  
    .homePageDivSecondCild {
      position: absolute;
      bottom: -150px;
      left: 50%;
      transform: translateX(-50%);
  }
  .backgroundFixedDivUpperLayer {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ourServicePopupedDiv {
  position: absolute;
  top: 10%;
  left: 3%;
}
  }



  @media  screen and (max-width:480px)  {

    .homeSection {
     
      background-size: cover;
  }
    .homeSection {
      height: 400px;
      /* padding-top: 9rem; */
    }
    .homeSectionSecondPartChild {
      max-width: 120px;
    margin-bottom: 5rem;
    padding: 2rem 0rem;
    /* margin-top: 0px; */


    }
    .homeSectionIconsText {
      font-size: 1rem;
    }
    .homeSectionSecondPartChild:nth-of-type(1) {
      padding: 2rem 1rem;
    }
    .homeSectionSecondPartChild>h5 {
      font-size: 1rem;
    }
    .typeWriterAnimationTextTwo {
      font-size: 2rem !important;
    }


    .firstBgFixedDiv {
      background: 
      linear-gradient(90deg,rgba(0,0,0,.8) 10%,rgba(0,0,0,.5)),url(https://customersfirstacademy.com/wp-content/uploads/2021/04/How-to-keeping-customers-happy.jpg);
      background-image: url(https://www.qualityformationsblog.co.uk/wp-content/uploads/2020/04/QCF_Meeting-1.jpg);
      background-attachment: fixed;
      width: 100vw;
      height: auto;
      position:relative;
      padding: 2rem 0;
      background-repeat: no-repeat;
      background-size: conver;
  }
  .backgroundFixedDivUpperLayer {
    color: white;
    padding: 3rem .2rem;
    padding-bottom: 2rem;
}
.ourServicePopupedDiv {
padding-left: 2rem;
}
.CheersTo {
  margin-top: 2rem;
}
  }
  


.logoAnimation {
  animation: tilt-in-left-2 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both infinite;
  /* animation: tilt-in-right-2 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both infinite */

}
  @keyframes tilt-in-left-2 {
    0% {
      transform: rotateX(30deg) translateX(-300px) skewX(30deg);
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg) translateX(0) skewX(0deg);
      opacity: 1;
    }
  }

  @keyframes tilt-in-right-2 {
    0% {
      transform: rotateX(30deg) translateX(300px) skewX(-30deg);
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg) translateX(0) skewX(0deg);
      opacity: 1;
    }
  }