/* .industriesRoutePage {
    padding-top: 5rem;
} */

.industriesLanding{
    height: 320px;
    background: 
    linear-gradient(90deg,rgba(0,0,0,.8) 10%,rgba(0,0,0,.8)),url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/business-team-meeting.jpg);

    /* background-image: url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/business-team-meeting.jpg); */
    background-size: cover;
    padding-top: 11rem;


}
.industriesFixedDiv {
    width: 100vw;
    height: 60vh;
    background: linear-gradient(90deg,rgba(0,0,0,.7) 10%,rgba(0,0,0,.6)),url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/young-lady-using-voice-assistant-on-cellphone-and-pc.jpg);
background-size: cover;
background-attachment: fixed;
padding: 0 3rem ;
padding-top: 6rem;

}

@media screen and (max-width: 450px) {
    .industriesFixedDiv {
        padding-top: 3rem;
    }
    
}