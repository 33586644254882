.ITManageSecondPart {
    /* height: 200px; */
    background: #ECECEC;
    animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}


.ITManagePartChild {
    max-width: 165px;
    padding: 2rem;
    position: relative;
    margin-top: -50px;
}



#ITManageIcons {
    font-size: 2rem;
    margin-top: .9rem;

    color: white;
}
.ITManageIconParent {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
        background: #FF5722;
        width: 60px;
        height: 60px;
        border-radius: 5px;
}