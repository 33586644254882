.fourthChildren:last-child {
 border-left : 1px solid #1B2B4E
}
.NetworkTransformationTitle {
    
  font-size: 4rem;
}
.fifthNetworkTransformationImage {
  width: 500px;

}
.sixthNetworkTransformationImage {
  width: 450px;
}
@media  screen and (max-width:480px)  {
    .NetworkTransformationTitle {
    
        font-size: 3rem;
      }
      .fourthChildren:last-child {
        border-top : 1px solid #1B2B4E; 
        padding-top: 1rem;

        border-left: 0px solid red;
       }
       .fifthNetworkTransformationImage {
        width: 100%;
      
      }
      .sixthNetworkTransformationImage {
        width: 100%;
      }
}