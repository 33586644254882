.firstDataCenterTitle {
    font-weight: bold;
    font-size: 4rem;
}

.firstDataCenterImage {
    width: 500px;
}


@media  screen and (max-width:480px)  {
    .firstDataCenterTitle {
        font-weight: bold;
        font-size: 2rem;
    }
    .firstDataCenterImage {
        width: 100%;
    }
    
}