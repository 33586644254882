.contactLandingText {
    font-size: 3rem;
     font-weight: bold;

}


.contactFirstPage {
    background: url(https://valuepointtech.com/wp-content/uploads/2021/08/Object2.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 50vw;
    margin: auto;
    height: 180px;
    margin-top: 6rem;
}
.contactFirstPageSecond {
    margin-top: 1rem;
}
.contactFirstPageThird {
    margin-top: 1rem;
}
.smallunderline {
    width: 100px;
    height: 3px;
    background-color: #FF5722;
}
.underline {
    width: 100px;
    height: 3px;
    background: #FF5722;
    margin: auto;
    margin-bottom: 1rem;
}





.firstCP{
    animation:slide-right 1s cubic-bezier(.25,.46,.45,.94) both;

}
.fistCPForm>input{
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    background: #F9F9F9;
    /* border: none; */
    border-color: #FF5722;
    outline: none;
    border-radius: 4px;
}
.fistCPForm>textarea{
    width: 100%;
    background: #F9F9F9;
    /* border: none; */
    border: 2px solid;
    border-color: #FF5722;

    outline: none;
    margin-bottom: 1rem;
    border-radius: 4px;

}
.secondCP{
   animation:scale-in-center 1s cubic-bezier(.25,.46,.45,.94) both;
   padding-top: 5.5rem !important;
}
.secondCPchild {
    background-image: url(https://lithohtml.themezaa.com/images/quotes-01.png);
    background-position: center;
    border: "1px solid red";

}
.contactModerntext{
    position: relative;
    margin-left: 7rem;
}

.contactModerntext::after{
    content: "";
    width: 100px;
    height: 3px;
    border: 2px solid #0DCAF0;
    position: absolute;
    left: -110px;
    top: 50%;
    /* transform: translateX(-20%); */
    transform: translateY(-50%);
}

.contactLandingTextDiv {
    height: 400px;
    padding-top: 8rem;
    padding-bottom: 9rem;
    padding-left: 5rem;
          /* // background: #D8D8D8; */
    background-Image: url(https://hawk-sense.com/assets/images/aboutus/contactus2.jpg);
    background-size: cover;
}

.notification {
    border: 1px solid green;
    padding: .5rem 3rem;
    border-radius: 50px;
    margin-left: 2rem;
    font-weight: bold;
    font-size: 1rem;
}
.sendMessage {
    padding: 1rem 2rem;
    border-radius: 20px 0px;
    color: rgb(127,127,127);
font-weight: bold;
border: 3px solid #FF5722;
}

@media screen and (min-width: 450px) 
{
    .minWidth400div {
        min-width: 450px;
    }
}

@media screen and (max-width: 550px) {
    

.contactFirstPage {
    width: 100vw;
    margin: auto;
    height: 100px;
    margin-top: 0rem;
}
    .contactLandingText {
        font-size: 2rem;
         font-weight: bold;
         color: red
    
    }
   
.contactLandingTextDiv {
    padding-left: 1rem;
    padding-top: 10rem;
}
.contactLandingTextDiv>h1 {
    font-size: 2.2rem;
}
.secondCP{
    padding-top: .5rem !important;
 
 }
}
