.firstCloudTransformationTitle {
    font-weight: bold;
    font-size: 4.5rem;
     color:#263351;
}

.firstCloudTransformationImage {
    width: 600px;
}
.thirdCloudTransImage {
     width: 500px;
      border-radius: 0 50px 0 50px
}
.fourthCloudTrans {
        background: #E3F4FC;
        width: 100vw;
        height: 100vh;
        background-image:
          url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/image-2_elements-isometric-biometric-technology-vector-concept-2SVBJ5Y-2019-06-23.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 75vw -80px;
        position: relative;
}
.fourthCloudTransSecond {
        height: 100%;
        background-image:
          url(https://www.valuepointsystems.com/wp-content/uploads/2021/11/image-1_elements-isometric-biometric-technology-vector-concept-2SVBJ5Y-2019-06-23.png);
        background-repeat: no-repeat;
        background-size: contain;
}
@media  screen and (max-width:480px)  {
    .firstCloudTransformationTitle {
        font-weight: bold;
        font-size: 2.5rem;
         color:#263351;
    }
    .firstCloudTransformationImage {
        width: 100%;
    }
    .thirdCloudTransImage {
        width: 100%;
   }
   .fourthCloudTrans {
    height: auto;
   }
.fourthCloudTransSecond {
    height: 100%;
   background:white;
    background-repeat: no-repeat;
    background-size: contain;
}
}


@media  screen and (min-width:480px)  {
.fourthCloudTransThird {
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
}
}